import { ChangeDetectionStrategy, Component, DestroyRef, type OnInit, computed, inject, input } from '@angular/core';

import { NgClass } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppFilterSelectFieldComponent } from '@ft/lib/filter-lib';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { NgSelectModule } from '@ng-select/ng-select';

import { CompetitionService } from '@danceShared/competitions-shared/data/competition.service';
import { SyllabusService } from '@danceShared/syllabuses-shared/data/syllabus.service';
import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { AppTaperedBorderDirective } from '@ft/lib/directives';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { EntryRanges } from '@furnas-technology/common-library/competitions';
import { DateRanges } from '@furnas-technology/common-library/functions';
import { CompetitionOpenFN, CompetitionsFilterService, EntryOpenFN } from '../competitions-filter.service';

const FILTER_DEBOUNCE_TIME = 300;
@Component({
	selector: 'ft-competitions-filter-form',
	templateUrl: './competitions-filter-form.component.html',
	styleUrls: ['./competitions-filter-form.component.scss'],
	imports: [
		NgClass,
		AppFilterSelectFieldComponent,
		AppTaperedBorderDirective,
		FontAwesomeModule,
		FormsModule,
		MatCheckboxModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatDividerModule,
		MatButtonModule,
		NgSelectModule,
		MatCheckboxModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsFilterFormComponent implements OnInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	fb = inject(FormBuilder);
	afs = inject(ActiveFilterService);

	competitionFilters = inject(CompetitionsFilterService);
	syllabusService = inject(SyllabusService);

	competitionService = inject(CompetitionService);
	rowsLoading = computed(() => this.competitionService.isLoading());

	// input params
	appFilters = input<boolean>(false);

	submitTitle = 'Apply';

	dateRanges = [...DateRanges];
	entryRanges = [...EntryRanges];

	CompetitionOpenFN = CompetitionOpenFN;
	EntryOpenFN = EntryOpenFN;

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	ngOnInit() {}

	onSubmit() {}
}
