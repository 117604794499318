<form class="app-filter-form-component" (ngSubmit)="onSubmit()" [ngClass]="layout.cssClasses()">
  <section class="section" appTaperedBorder="var(--mat-sys-outline)" position="bottom" positionMargin="2vh">
    <!-- additional info -->
    <div class="filter-field checkbox-field">
      <mat-checkbox
        aria-label="Competition Open"
        class="select"
        labelPosition="before"
        [(ngModel)]="competitionFilters.openCompetition"
        (change)="competitionFilters.onChangeOpenCompetition(CompetitionOpenFN, competitionFilters.openCompetition())"
        [ngModelOptions]="{ standalone: true }"
        ><p>{{ competitionFilters.openCompetitionsText() }}</p></mat-checkbox
      >
    </div>

    <div class="filter-field checkbox-field">
      <mat-checkbox
        aria-label="Entries Open"
        class="select"
        labelPosition="before"
        [(ngModel)]="competitionFilters.openEntry"
        (change)="competitionFilters.onChangeOpenEntry(EntryOpenFN, competitionFilters.openEntry())"
        [ngModelOptions]="{ standalone: true }"
        ><p>{{ competitionFilters.openEntriesText() }}</p></mat-checkbox
      >
    </div>
  </section>

  <section class="section">
    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Competition Dates"
      fieldPath="_dateRanges"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.dateRanges()"
      [hideTotals]="true"
    ></ft-app-filter-select-field>

    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Entry Dates"
      fieldPath="_entryRanges"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.entryRanges()"
      [hideTotals]="true"
    ></ft-app-filter-select-field>
  </section>

  <section class="section">
    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="States"
      fieldPath="_statecode"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.statecodes()"
    ></ft-app-filter-select-field>

    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Cities"
      fieldPath="_city"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.cities()"
    ></ft-app-filter-select-field>

    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Countries"
      fieldPath="_countrycode"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.countrycodes()"
    ></ft-app-filter-select-field>
  </section>

  <section class="section">
    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Styles"
      fieldPath="_syllabus._baseCompStyles"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.baseCompStyles()"
    ></ft-app-filter-select-field>

    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Category"
      fieldPath="_syllabus._categoryTypes"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.categoryTypes()"
    ></ft-app-filter-select-field>

    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Solo or Group"
      fieldPath="_syllabus._compSoloOrGroups"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.compSoloOrGroups()"
    ></ft-app-filter-select-field>

    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Sections"
      fieldPath="_syllabus._sections"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.sections()"
    ></ft-app-filter-select-field>
  </section>

  <section class="section">
    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Age Types"
      fieldPath="_ageTypes"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.filteredAgeTypes()"
    ></ft-app-filter-select-field>

    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Ages"
      fieldPath="_ages"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.filteredAges()"
    ></ft-app-filter-select-field>

    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="School Years"
      fieldPath="_schoolYears"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.filteredYears()"
    ></ft-app-filter-select-field>
  </section>

  <!-- Competitions -->
  <section class="section" appTaperedBorder="var(--mat-sys-outline)" position="top" positionMargin="2vh">
    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Competitions"
      fieldPath="competitionName"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.competitionNames()"
    ></ft-app-filter-select-field>
  </section>

  <!-- Locations -->
  <section class="section">
    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Venue Locations"
      fieldPath="_formattedAddress"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="competitionService.venueLocations()"
    ></ft-app-filter-select-field>
  </section>
</form>
