import { CompetitionSearchBarFields } from '@danceShared/competitions-shared/competition.model';
import { CompetitionsFilterFormComponent } from '@danceShared/competitions-shared/filters/competitions-filter-form/competitions-filter-form.component';
import { CompetitionOpenFN } from '@danceShared/competitions-shared/filters/competitions-filter.service';
import { ComponentFilterDefinition } from '@ft/lib/active-filter-lib';
import { Competition } from '@furnas-technology/common-library/competitions';

export const CompetitionFilter: ComponentFilterDefinition<Competition> = {
	filterName: 'competitions',
	component: CompetitionsFilterFormComponent,
	initialFilters: [{ fieldnames: CompetitionOpenFN, defaultOperation: 'yes' }],
	searchBarFields: CompetitionSearchBarFields,
};
