import type { Route } from '@angular/router';
import {
	faBook,
	faCalendarDays,
	faDatabase,
	faHome,
	faList,
	faLocationDot,
	faPersonThroughWindow,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { adminGuard, loggedInGuard, subscribedGuard } from '@ft/lib/auth-lib';
import { FT_CreateRouteData } from '@ft/lib/models-routing';
import { CompetitionFilter } from './app-filter.constants';
import { DATAMANAGEMENT_ROUTES } from './menu-children.constants';

export const CONTENT_ID = 'contentId';
export const APP_ADMIN_GROUPS = ['admins'];
export const APP_USER_GROUPS = ['users'];

const homeRouteData = FT_CreateRouteData({
	icon: faHome,
	hasSidebar: false,
	componentFilter: undefined,
	searchBarInFilterComponent: false,
	showInNavbar: false,
});

const mapRouteData = FT_CreateRouteData({
	icon: faLocationDot,
	hasSidebar: false,
	componentFilter: CompetitionFilter,
	searchBarInFilterComponent: true,
	showInNavbar: true,
});

const listRouteData = FT_CreateRouteData({
	icon: faList,
	hasSidebar: false,
	componentFilter: CompetitionFilter,
	searchBarInFilterComponent: true,
	showInNavbar: true,
});

const calendarRouteData = FT_CreateRouteData({
	icon: faCalendarDays,
	hasSidebar: false,
	componentFilter: CompetitionFilter,
	searchBarInFilterComponent: true,
	showInNavbar: true,
	allowedGroups: ['admins', 'subscription_active'],
});

const organisationRouteData = FT_CreateRouteData({
	icon: faUsers,
	allowedGroups: ['admins'],
	showInNavbar: true,
});

const competitionRouteData = FT_CreateRouteData({
	icon: faCalendarDays,
	allowedGroups: ['admins'],
	componentFilter: CompetitionFilter,
	showInNavbar: true,
});

const syllabusRouteData = FT_CreateRouteData({
	icon: faBook,
	authenticatedStatus: 'authenticated',
	allowedGroups: ['admins'],
	showInNavbar: true,
});

const dataRouteData = FT_CreateRouteData({
	icon: faDatabase,
	authenticatedStatus: 'authenticated',
	allowedGroups: ['admins'],
	children: [...DATAMANAGEMENT_ROUTES],
	noRoute: true,
	showInNavbar: true,
});

const userRouteData = FT_CreateRouteData({
	icon: faPersonThroughWindow,
	allowedGroups: ['admins'],
	showInNavbar: true,
});

export const APP_ROUTES: Route[] = [
	/**
	 * Competition Map
	 */
	{
		title: 'Home',
		path: 'home',
		canActivate: [],
		data: homeRouteData,
		loadComponent: () =>
			import('@danceShared/competitions-shared/components/competition-dashboard/competition-dashboard.component').then(
				(mod) => mod.CompetitionDashboardComponent,
			),
	},

	/**
	 * Competition Map
	 */
	{
		title: `Map`,
		path: 'map',
		canActivate: [],
		data: mapRouteData,
		loadComponent: () =>
			import('@danceShared/competitions-shared/components/competition-map/competition-map.component').then(
				(mod) => mod.MapPageComponent,
			),
	},
	{
		path: 'map/:contentId',
		canActivate: [],
		data: mapRouteData,
		loadComponent: () =>
			import('@danceShared/competitions-shared/components/competition-map/competition-map.component').then(
				(mod) => mod.MapPageComponent,
			),
	},

	/**
	 * Competition List table
	 */
	{
		title: `List`,
		path: 'list',
		canActivate: [loggedInGuard],
		data: listRouteData,
		loadComponent: () =>
			import('@danceShared/competitions-shared/components/competition-list/competition-list.component').then(
				(mod) => mod.CompetitionListComponent,
			),
	},
	{
		path: 'list/:contentId',
		canActivate: [loggedInGuard],
		data: listRouteData,
		loadComponent: () =>
			import('@danceShared/competitions-shared/components/competition-list/competition-list.component').then(
				(mod) => mod.CompetitionListComponent,
			),
	},

	/**
	 * Competition Calendar
	 */
	{
		title: `Calendar`,
		path: 'calendar',
		canActivate: [subscribedGuard],
		data: calendarRouteData,
		loadComponent: () =>
			import('@danceShared/competitions-shared/components/competition-calendar/competition-calendar.component').then(
				(mod) => mod.CompetitionCalendarComponent,
			),
	},
	{
		path: 'calendar/:contentId',
		canActivate: [subscribedGuard],
		data: calendarRouteData,
		loadComponent: () =>
			import('@danceShared/competitions-shared/components/competition-calendar/competition-calendar.component').then(
				(mod) => mod.CompetitionCalendarComponent,
			),
	},

	/**
	 * Organisations
	 */
	{
		title: `Organisations`,
		path: 'organisations',
		canActivate: [adminGuard],
		data: organisationRouteData,
		loadComponent: () =>
			import(
				'@danceShared/organisations-shared/components/organisation-management/organisation-management.component'
			).then((mod) => mod.OrganisationManagementComponent),
	},
	{
		path: 'organisations/:contentId',
		canActivate: [adminGuard],
		data: organisationRouteData,
		loadComponent: () =>
			import(
				'@danceShared/organisations-shared/components/organisation-management/organisation-management.component'
			).then((mod) => mod.OrganisationManagementComponent),
	},

	/**
	 * Competitions
	 */
	{
		title: `Competitions`,
		path: 'competitions',
		canActivate: [adminGuard],
		data: competitionRouteData,
		loadComponent: () =>
			import(
				'@danceShared/competitions-shared/components/competition-management/competition-management.component'
			).then((mod) => mod.CompetitionManagementComponent),
	},
	{
		path: 'competitions/:contentId',
		canActivate: [adminGuard],
		data: competitionRouteData,
		loadComponent: () =>
			import(
				'@danceShared/competitions-shared/components/competition-management/competition-management.component'
			).then((mod) => mod.CompetitionManagementComponent),
	},

	/**
	 * Syllabuses
	 */
	{
		title: `Syllabuses`,
		path: 'syllabuses',
		canActivate: [adminGuard],
		data: syllabusRouteData,
		loadComponent: () =>
			import('@danceShared/syllabuses-shared/components/syllabus-management/syllabus-management.component').then(
				(mod) => mod.SyllabusManagementComponent,
			),
	},
	{
		path: 'syllabuses/:contentId',
		canActivate: [adminGuard],
		data: syllabusRouteData,
		loadComponent: () =>
			import('@danceShared/syllabuses-shared/components/syllabus-management/syllabus-management.component').then(
				(mod) => mod.SyllabusManagementComponent,
			),
	},

	/**
	 * Data Management
	 */
	{
		title: `Data Management`,
		path: 'data-management',
		canActivate: [adminGuard],
		data: dataRouteData,
		loadChildren: () => import('./menu-children.constants').then((mod) => mod.DATAMANAGEMENT_ROUTES),
	},

	/**
	 * Users
	 */
	{
		title: 'Users',
		path: 'users',
		canActivate: [adminGuard],
		data: userRouteData,
		loadComponent: () =>
			import('projects/ft/lib/users-lib/components/user-list/user-list.component').then((mod) => mod.UserListComponent),
	},
];
